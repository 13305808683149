html {
    position: relative;
    min-height: 100%;
}

#root {
    /* Margin bottom by footer height */
    margin-bottom: 75px;
}

#root > footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: 60px;
    background-color: rgb(241, 241, 241);
    margin-top: 15px;
    padding: 5px;
    font-size: 80%;
    border-top: 1px solid black;
}
#root > footer > p {
    margin: 0;
    padding: 0;
}


.btn-group {
	flex-wrap: wrap;
}
.btn-group > .btn {
	flex: 0 1 auto;
}

.nav .nav-item {
	border-bottom: none;
	border-right: 1px solid #343A40;
}
.nav .nav-item:first-child {
	border-left: 1px solid #343A40;
}

.nav.flex-column .nav-item {
	border-right: none;
	border-bottom: 1px solid #343A40;
}
.nav.flex-column .nav-item:first-child {
	border-left: none;
	border-top: 1px solid #343A40;
}

.nav .nav-link {
	color: #343A40;
}

.nav .nav-link:hover {
	background-color: rgba(52, 58, 64, 0.60);
	color: #FFF;
}

.nav .nav-link.active-link {
	background-color: #343A40;
	color: #FFF;
}

.nav .collapse .nav-link, .nav .collapsing .nav-link {
	background-color: rgba(23, 178, 168, 0.50);
}

.nav .collapse .nav-link:hover, .nav .collapsing .nav-link:hover {
	background-color: rgba(23, 178, 168, 0.75);
	color: #FFF;
}

.nav .collapse .nav-link.active-link, .nav .collapsing .nav-link.active-link {
	background-color: #343A40;
	color: #FFF;
}



.page-item.active .page-link {
	background-color: #17B2A8;
	border-color: #17B2A8;
}
.page-link {
	color: #343A40;
}


.table th, .table td {
	vertical-align: middle;
}

.table-hover tbody tr:hover {
	background-color: rgba(23, 178, 168, 0.25);
}


a {
	color: #17B2A8;
}
a:hover {
	color: #08403C;
	text-decoration: none;
}

.big-checkbox {
	width: 30px; 
	height: 30px;
}

.marginBottomProf {
	margin-bottom: 100px;
}